import React, { Component } from 'react';
import Anime from 'animejs/lib/anime.es.js';
import BackgroundImage from 'gatsby-background-image';
import '../style/chapter_hero.scss';
import '../../node_modules/font-awesome/css/font-awesome.min.css';

class ChapterHero extends Component {

  handleTextAnimation() {

    const headline = this.props.headline;
    const subheader = this.props.subheader;
    let element = document.getElementById('headline-text');
    element.innerText = headline;
    let element2 = document.getElementById('subheader-text');
    element2.innerText = subheader;

    Anime({
      targets: '#headline-text, #subheader-text',
      easing: 'linear',
      opacity: 1.0,
      duration: 2000
    })

  }

  componentDidMount() {
    this.handleTextAnimation();
  }

  render() {
    return (
      <div className={this.props.classList}>
        <BackgroundImage id='chapter-hero-img' fluid={this.props.img}>
        </BackgroundImage>
        <div id='chapter-headline'>
          <div id='headline-text-wrapper'>
            <h2 id='headline-text'></h2>
            <h4 id='subheader-text'></h4>
          </div>
        </div>
      </div>
    )
  }

}

export default ChapterHero;
