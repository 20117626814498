import React, { Component } from 'react';
import Layout from '../components/layout.js';
import ChapterHero from '../components/chapter_hero.js';
import DecoBorder from '../components/deco_border.js';
import { Helmet } from "react-helmet";
import '../style/careers.scss';

class Careers extends Component {

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers</title>
          <link rel="canonical" href="https://www.tobyrichey.com/careers" />
        </Helmet>
        <ChapterHero
          headline=''
          subheader=''
          classList='chapter-hero-container small'
          img={this.props.data.careers.childImageSharp.fluid}/>
        <DecoBorder />
        <div id='contact-container'>
          <div id='contact' className='chapter-content'>
            <div className='body-container'>
              <div className='text-block'>
                <div id='text'>

                  <h3>
                  A firm that focuses on careers might be a great place to continue yours.
                  </h3>
                  <p>
                  If you’re looking for a place that will challenge you,
                  that will help you grow, you’ve found it. At TRA,
                  you’ll be working with some of the best professionals
                  in the business for a firm that values individual
                  talent as much as teamwork.
                  </p>

                  <div className='hor-rule' />

                  <div id='call-to-action-block-container'>
                    <div id='icon-container'>
                      <div id='icon' />
                    </div>
                    <div id='call-to-action-block'>
                      <h3 className='special-headline'>Join Us.</h3>
                      <p>
                        If you’re interested in pursuing a career with Toby Richey & Associates, please contact us below.
                      </p>
                      <a href="mailto:careers@tobyrichey.com">Inquire</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="svg-outer svg-outer-chapter-page">
            <svg className='svg-inner gray' width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
             <polygon points="0,0 0,100 100,100 100,0 50,98" />
            </svg>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    careers: file(relativePath: { eq:
    "careers05.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`;

export default Careers;
